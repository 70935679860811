@import 'primeicons/primeicons.css';
@import 'vue-select/dist/vue-select.css';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light-border.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --green: #32bea6;
  --red: #ff2f5e;
  --blue: #253d9a;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Thin.woff2') format('woff2'), url('../fonts/Metropolis-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-ExtraLight.woff2') format('woff2'),
    url('../fonts/Metropolis-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Light.woff2') format('woff2'), url('../fonts/Metropolis-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Regular.woff2') format('woff2'), url('../fonts/Metropolis-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Medium.woff2') format('woff2'), url('../fonts/Metropolis-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-SemiBold.woff2') format('woff2'),
    url('../fonts/Metropolis-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Bold.woff2') format('woff2'), url('../fonts/Metropolis-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-ExtraBold.woff2') format('woff2'),
    url('../fonts/Metropolis-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Black.woff2') format('woff2'), url('../fonts/Metropolis-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@layer base {
  body {
    @apply text-sm text-default;
  }

  label {
    @apply text-xs font-bold;
  }

  /* Scrollbar */
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    @apply bg-zinc-400;
    border-radius: 25px;
  }

  ::-webkit-file-upload-button {
    @apply bg-primary hover:bg-amber-500 active:opacity-75 text-white;
    @apply cursor-pointer border-none py-2 px-4 mr-4;
  }
}

@layer components {
  /* Sidebar */
  .sidebar-item {
    @apply w-full lg:pl-8 md:pl-0 py-2 text-white text-center 2xl:text-left;
    @apply hover:bg-primary text-white;
  }

  .sidebar-item > img {
    @apply 2xl:inline mx-auto;
  }

  .sidebar-item > span {
    @apply text-xs 2xl:text-sm 2xl:ml-4 2xl:align-middle font-medium;
  }

  .sidebar-item.active {
    @apply border-r-4 border-primary text-white bg-primary;
  }

  .sidebar-item.active > span {
    @apply font-medium text-white;
  }

  .sidebar-donut-1 {
    @apply absolute rounded-full;
    left: -41px;
    width: 102px;
    height: 102px;
    border: 30px solid rgba(255, 231, 195, 0.6);
  }

  .sidebar-donut-2 {
    @apply absolute rounded-full;
    top: 120px;
    right: -52px;
    width: 178px;
    height: 178px;
    border: 2px dashed rgba(255, 231, 195, 0.8);;
  }

  .sidebar-donut-3 {
    @apply absolute rounded-full;
    bottom: -40px;
    left: -40px;
    width: 200px;
    height: 200px;
    border: 20px solid rgba(255, 231, 195, 0.3);;
  }

  /* Container */
  .content {
    @apply 3xl:container 3xl:mx-auto px-10 py-6 pb-32;
  }

  /* Button */
  .btn-primary {
    @apply inline-flex items-center justify-center;
    @apply bg-primary hover:bg-blue-600 active:opacity-75;
    @apply text-white font-bold text-xs cursor-pointer;
    @apply rounded-3xl px-8 py-3 min-w-[150px];
  }
  .btn-primary:disabled,
  .btn-primary.disabled {
    @apply cursor-default pointer-events-none opacity-80;
  }

  .btn-secondary {
    @apply inline-flex items-center justify-center;
    @apply bg-secondary hover:bg-orange-400 active:opacity-75;
    @apply text-white font-bold text-xs cursor-pointer;
    @apply rounded-3xl px-8 py-3 min-w-[150px];
  }
  .btn-secondary:disabled,
  .btn-secondary.disabled {
    @apply cursor-default pointer-events-none opacity-80;
  }

  .btn-outline {
    @apply inline-flex items-center justify-center;
    @apply bg-white hover:bg-orange-50 active:bg-orange-100;
    @apply text-primary font-bold text-xs cursor-pointer;
    @apply border border-primary rounded-3xl px-8 py-3 min-w-[150px];
  }
  .btn-outline:disabled,
  .btn-outline.disabled {
    @apply cursor-default pointer-events-none opacity-80 bg-zinc-100;
  }
  .btn-outline-sm {
    @apply btn-outline;
    @apply px-3 py-0.5 min-w-min;
  }
  .btn-outline-sm:disabled {
    @apply cursor-default;
  }

  .btn-red {
    @apply inline-flex items-center justify-center;
    @apply bg-[#EA4444] hover:bg-red-600 active:bg-red-500;
    @apply text-white font-bold text-xs cursor-pointer;
    @apply rounded-3xl px-8 py-3 min-w-[150px];
  }
  .btn-red:disabled {
    @apply cursor-default;
  }

  .btn-table-action-primary {
    @apply bg-primary hover:bg-amber-500 active:opacity-75;
    @apply text-white font-bold text-[10px] cursor-pointer;
    @apply rounded px-2 py-0.5;
  }
  .btn-table-action-primary:disabled {
    @apply cursor-default;
  }

  .btn-table-action-outline {
    @apply bg-slate-50 hover:bg-orange-50 active:bg-orange-100;
    @apply text-primary font-bold text-[10px] cursor-pointer;
    @apply border border-primary rounded px-2 py-0.5;
  }
  .btn-table-action-outline:disabled {
    @apply cursor-default;
  }

  .btn-table-action-green {
    background-color: var(--green);
    @apply hover:bg-teal-500 active:opacity-75;
    @apply text-white font-bold text-[10px] cursor-pointer;
    @apply rounded px-2 py-0.5;
  }
  .btn-table-action-green:disabled {
    @apply cursor-default;
  }

  .btn-table-action-red {
    background-color: var(--red);
    @apply hover:bg-red-600 active:opacity-75;
    @apply text-white font-bold text-[10px] cursor-pointer;
    @apply rounded px-2 py-0.5;
  }
  .btn-table-action-red:disabled {
    @apply cursor-default;
  }

  .btn-modal-close {
    @apply btn-outline;
    @apply border-none rounded-full p-1 text-xs;
    @apply w-6 h-6 min-w-min;
  }

  .btn-dropdown {
    @apply inline-block relative;
  }
  .btn-dropdown > ul {
    @apply absolute right-0 w-48 mt-2;
    @apply rounded-sm border border-gray-300 bg-white;
    @apply z-30;
  }
  .btn-dropdown li > a {
    @apply w-full bg-white hover:bg-gray-200 py-2 px-4 block font-medium;
  }
  .btn-dropdown li:not(:last-child) {
    @apply border-b;
  }

  .dropdownwidth ul {
    @apply w-auto;
  }

  /* Form */
  .form-input {
    @apply w-full block rounded-lg;
    @apply border-gray-200 focus:border-primary focus:ring-0;
    @apply text-sm text-dark;
  }
  .form-input::placeholder {
    @apply text-default;
  }
  .form-input:read-only {
    @apply bg-gray-100 focus:border-gray-200;
  }

  .form-input.form-date:read-only {
    @apply bg-white cursor-pointer;
  }
  .form-input.form-date:disabled {
    @apply bg-gray-100 cursor-default;
  }
  .form-input.form-date.active {
    @apply border-primary;
  }

  .form-input-icon {
    @apply relative;
  }
  .form-input-icon .icon {
    @apply absolute inset-y-0 left-0 pl-4 flex items-center;
  }
  .form-input-icon .icon-right {
    @apply absolute inset-y-0 right-0 pr-4 flex items-center;
  }
  .form-input-icon .pi {
    @apply text-xs;
  }
  .form-input-icon input {
    @apply pl-9;
  }

  .form-select {
    @apply w-full block rounded-lg;
    @apply border-gray-200 focus:border-primary focus:ring-0;
    @apply text-sm text-dark;
  }

  .form-v-select .vs__dropdown-toggle {
    @apply rounded-lg border-gray-200;
  }
  .form-v-select .vs__dropdown-toggle[aria-expanded='true'] {
    @apply rounded-bl-none rounded-br-none border-primary;
  }
  .form-v-select .vs__search {
    @apply text-sm text-dark py-1 px-3;
  }
  .form-v-select .vs__search::placeholder {
    @apply text-default;
  }
  .form-v-select .vs__selected {
    @apply py-1 px-2;
  }
  .form-v-select .vs__dropdown-menu {
    @apply border-primary shadow-none border-t border-t-gray-200;
    border-top-style: solid;
  }
  .form-v-select .vs__dropdown-option--highlight {
    @apply bg-primary;
  }
  .form-v-select .vs__dropdown-option {
    @apply px-3;
  }
  .form-v-select .vs__actions svg {
    @apply fill-dark;
  }
  .form-v-select .vs__clear > svg {
    @apply scale-75;
  }
  .form-v-select .vs__open-indicator {
    transform: scale(0.75);
    transition: transform 0.15s cubic-bezier(1, -0.115, 0.975, 0.855);
    transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  }
  .form-v-select .vs__dropdown-toggle[aria-expanded='true'] .vs__open-indicator {
    transform: rotate(180deg) scale(0.75);
  }

  .form-radio,
  .form-check {
    @apply inline-flex items-center w-full border-none;
  }
  .form-radio > input,
  .form-check > input {
    @apply border-gray-400 focus:border-gray-400 focus:ring-0 accent-amber-600;
  }
  .form-radio > span,
  .form-check > span {
    @apply ml-2 text-default;
  }

  .form-radio-group {
    @apply rounded-lg border;
  }
  .form-radio-group .radio {
    @apply inline-flex;
  }
  .form-radio-group .radio > input {
    @apply hidden;
  }
  .form-radio-group .radio > label {
    @apply self-center py-2 px-4 cursor-pointer;
    @apply font-normal text-sm text-center;
    @apply hover:bg-orange-50 active:opacity-75;
  }
  .form-radio-group .radio:first-child {
    @apply rounded-tl-lg rounded-bl-lg overflow-hidden;
  }
  .form-radio-group .radio:last-child {
    @apply rounded-tr-lg rounded-br-lg overflow-hidden;
  }
  .form-radio-group .radio:not(:last-child) {
    @apply border-r;
  }
  .form-radio-group input:checked ~ label {
    @apply bg-primary text-white hover:bg-primary hover:opacity-75;
  }

  .form-file {
    @apply border border-gray-200 rounded cursor-pointer;
  }

  .form-error {
    @apply text-xs;
    color: var(--red);
  }

  /* Card */
  .card {
    @apply border rounded-2xl;
    @apply shadow-xl shadow-indigo-200/50;
  }

  /* Tab */
  .tab-item {
    @apply pb-2.5 text-xs;
  }
  .tab-item.active {
    @apply text-primary font-medium border-b border-b-primary;
  }

  /* Pill */
  .pill-item {
    @apply rounded-xl px-3 py-4 text-xs;
  }
  .pill-item.active {
    @apply text-white font-semibold bg-primary;
  }

  /* Table */
  .d-table th {
    @apply py-5 px-2 font-semibold;
    color: #999999;
  }
  .d-table td {
    @apply text-dark py-6 px-2;
  }
  .d-table.d-card-table th:first-child,
  .d-table.d-card-table td:first-child {
    @apply pl-14;
  }
  .d-table.d-card-table th:last-child,
  .d-table.d-card-table td:last-child {
    @apply pr-14;
  }
  .d-table.d-inline-table th:first-child,
  .d-table.d-inline-table td:first-child {
    @apply pl-10;
  }
  .d-table.d-inline-table td.ctxt {
    @apply text-center;
  }
  .d-table.d-inline-table th.ctxt > .p-column-header-content {
    @apply justify-center;
  }
  .d-table.d-inline-table th.ctxt > .p-column-header-content .p-sortable-column-icon {
    @apply hidden;
  }
  .d-table.d-inline-table td.rtxt {
    @apply text-right;
  }
  .d-table.d-inline-table th.rtxt > .p-column-header-content {
    @apply justify-end;
  }
  .d-table.d-inline-table th.rtxt > .p-column-header-content .p-sortable-column-icon {
    @apply hidden;
  }
  .d-table.d-inline-table th:last-child,
  .d-table.d-inline-table td:last-child {
    @apply pr-10;
  }
  .d-table.d-card-table > .p-datatable-wrapper {
    @apply bg-white border rounded-2xl;
    @apply shadow-xl shadow-indigo-200/50;
  }
  .d-table tr:nth-child(odd) td {
    background: #f2f3f5;
  }

  .d-table .p-sortable-column.p-highlight {
    @apply text-dark;
  }
  .d-table .p-sortable-column-icon.pi-sort-alt:before {
    content: '';
  }
  .d-table .p-sortable-column-icon.pi-sort-amount-up-alt:before {
    @apply text-xs;
    content: '\e914';
  }
  .d-table .p-sortable-column-icon.pi-sort-amount-down:before {
    @apply text-xs;
    content: '\e913';
  }

  .d-table .p-paginator {
    @apply mt-8;
  }
  .d-table .p-paginator-pages {
    @apply flex gap-1 mx-2;
  }
  .d-table .p-link {
    @apply border rounded-sm w-8 h-8 text-dark;
  }
  .d-table .p-link.p-highlight {
    @apply bg-primary text-white;
  }
  .d-table .p-link.p-disabled {
    @apply opacity-30;
  }

  .table-striped tbody tr:nth-child(odd) td {
    @apply bg-zinc-100;
  }

  .table-striped thead td {
    @apply font-bold;
  }

  .table-print thead th {
    @apply bg-[#034ea3] text-white;
    @apply whitespace-nowrap px-3 py-1;
  }
  .table-print tbody td,
  .table-print tfoot td {
    @apply whitespace-nowrap px-3 py-1;
  }
  .table-print tfoot td {
    @apply font-medium;
  }

  .table-modal th {
    @apply font-bold text-dark py-3;
  }

  .table-modal td {
    @apply text-center py-4;
  }

  /* Modal */
  .modal {
    @apply fixed inset-0 w-screen h-screen overflow-x-hidden overflow-y-scroll z-40 invisible;
    background: rgba(52, 52, 52, 0.6);
  }
  .modal-title {
    @apply text-lg font-bold;
  }
  .modal-subtitle {
    @apply block text-xs font-normal;
  }
  .modal-body {
    @apply relative w-1/3 mx-auto my-28 p-10;
    @apply flex flex-col gap-4;
    @apply bg-white rounded-2xl;
  }

  /* Indicator */
  .indicator-active {
    @apply rounded-full w-2 h-2 mr-1 inline-block;
    background-color: var(--green);
  }

  .indicator-inactive {
    @apply rounded-full w-2 h-2 mr-1 inline-block;
    background-color: var(--red);
  }

  .indicator-collected {
    @apply rounded-full w-2 h-2 mr-1 inline-block;
    background-color: var(--green);
  }

  .indicator-generated {
    @apply rounded-full w-2 h-2 mr-1 inline-block;
    background-color: var(--blue);
  }

  /* Alert */
  .alert-error {
    @apply flex items-center;
    @apply bg-rose-500 text-white w-full rounded-md px-3 py-2 font-medium;
  }

  /* Toast */
  .toast-success {
    @apply fixed top-[5.5rem] right-14 rounded-xl py-5 px-6 text-white;
    background-color: #75d010;
  }

  .toast-error {
    @apply bg-rose-500 fixed top-[5.5rem] right-14 rounded-xl py-5 px-6 text-white;
  }

  .toast-reject {
    @apply bg-rose-500 fixed top-[5.5rem] right-14 rounded-xl py-5 px-6 text-white;
    background-color: #f35a5a;
  }

  /* Link */
  a.disabled {
    @apply cursor-default pointer-events-none opacity-80;
  }
}

@layer utilities {
  .text-required {
    color: var(--red);
  }
  .text-required:before {
    content: '*';
  }

  .text-title {
    @apply text-2xl font-semibold text-secondary;
  }

  /* Datatable Columns */
  .p-items-start > .p-column-header-content {
    @apply !items-start;
  }
  .p-items-center > .p-column-header-content {
    @apply !items-center;
  }
}

/**
 * VueJS v-cloak
 */

[v-cloak] {
  display: none !important;
}

/* .sidebar-item.active svg {
  filter: invert(18%) sepia(80%) saturate(2649%) hue-rotate(206deg) brightness(92%) contrast(91%);
} */

.Approved {
  @apply bg-green-100 text-green-500 text-sm font-normal px-2.5 py-0.5 rounded-xl;
}

.For.Approval {
  @apply bg-green-100 text-green-500 text-sm font-normal px-2.5 py-0.5 rounded-xl;
}

.Pending {
  @apply bg-yellow-100 text-yellow-500 text-sm font-normal px-2.5 py-0.5 rounded-xl;
}

.Returned {
  @apply bg-red-100 text-red-500 text-sm font-normal px-2.5 py-0.5 rounded-xl;
}

.hide{
  @apply hidden;
}

input[type='number'].no-spinner::-webkit-inner-spin-button, 
input[type='number']::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
     margin: 0; 
}

.useravatarclass {
  width: 36px;
  height: 36px;
}

.failedclass {
  padding: 0.125rem 0.5rem;
  color: #ff0000;
  border: 1px dashed #ff0000;
  border-radius: 6px;
}

.failedclass::before {
  display: inline-block;
  margin-right: 0.25rem;
  content: "\1f6c8"; 
  font-size: 1rem;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  margin-left: -100px;
  margin-bottom: 10px;
  padding: 10px 15px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  width: 200px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  background-color: #555;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  margin-left: -5px;
  position: absolute;
  top: 100%;
  left: 50%;
  content: "";
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
